<template>
  <b-modal centered modal-class="add-report"
           id="addReportModalV2" hide-footer
           hide-header>
    <div class="w-full h-full">
      <div class="w-full  flex flex-col items-center pt-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-[1.875rem] h-[2.25rem]" viewBox="0 0 30 36" fill="none">
          <path
            d="M4.87875 35.2433C4.41585 35.0822 3.93051 34.97 3.49616 34.7518C2.08502 34.0483 1.24282 32.8962 0.961408 31.3464C0.906349 31.0384 0.898192 30.7183 0.898192 30.4042C0.896153 23.2935 0.896153 16.1807 0.902271 9.06787C0.902271 7.68324 1.36925 6.44136 2.31545 5.44214C2.98635 4.73453 3.76941 4.13296 4.50965 3.49468C5.10714 2.97876 5.73318 2.49139 6.31844 1.96323C7.33601 1.04354 8.44942 0.311463 9.82997 0.0789918C10.1848 0.0198544 10.5519 0.00557986 10.9128 0.00557986C15.3746 0.00150142 19.8385 -0.00461624 24.3003 0.00557986C25.7094 0.0096583 26.9349 0.519463 27.8832 1.56966C28.5724 2.33233 28.9986 3.24386 29.0374 4.29406C29.0721 5.19539 29.0965 6.09877 29.0986 7.00214C29.1026 14.792 29.1067 22.5797 29.0986 30.3696C29.0986 31.7623 28.6459 33.0022 27.5977 33.9586C26.8779 34.6132 26.0499 35.0863 25.0507 35.1842C25.0262 35.1862 25.0058 35.2229 24.9814 35.2433H4.87875ZM26.7392 17.6408C26.7392 13.4318 26.7392 9.22285 26.7351 5.0139C26.7351 4.73453 26.6984 4.44904 26.6352 4.17578C26.3558 2.9808 25.377 2.36088 24.2167 2.35884C19.7202 2.3466 15.2257 2.3466 10.7293 2.35476C10.0992 2.35476 9.48127 2.52402 8.9735 2.90535C8.12926 3.53955 7.32173 4.22269 6.49993 4.88543C5.89632 5.37281 5.28455 5.85202 4.69522 6.35367C3.83671 7.08575 3.24126 7.96262 3.24126 9.15148C3.24126 16.2276 3.23718 23.3037 3.24941 30.3798C3.24941 30.7611 3.33098 31.1547 3.44314 31.5197C3.64094 32.16 4.68502 32.8962 5.33146 32.8962C7.78464 32.8962 10.2399 32.89 12.693 32.888C16.5472 32.888 20.3992 32.8839 24.2534 32.89C24.8998 32.89 25.4484 32.6841 25.9296 32.2599C26.5332 31.7277 26.7392 31.0405 26.7392 30.2656C26.7392 26.0566 26.7392 21.8477 26.7392 17.6387V17.6408Z"
            fill="#2560D7"/>
          <path
            d="M14.9914 28.2015C12.63 28.2015 10.2685 28.2015 7.90713 28.2015C6.99356 28.2015 6.72031 27.6754 6.61427 27.0269C6.5327 26.5293 7.10368 25.9318 7.64611 25.8625C7.80313 25.8421 7.96627 25.8503 8.12533 25.8503C12.7788 25.8503 17.4323 25.8441 22.0879 25.8482C22.8118 25.8482 23.3216 26.3336 23.3196 27.033C23.3196 27.6998 22.7261 28.2076 22.0756 28.2056C19.7142 28.1933 17.3528 28.1994 14.9914 28.2015Z"
            fill="#2560D7"/>
          <path
            d="M15.0483 22.3205C12.7317 22.3205 10.4152 22.3225 8.09861 22.3205C7.34002 22.3205 6.86692 21.8433 6.87711 21.1112C6.88527 20.5178 7.37672 20.0101 7.98033 19.9774C8.1292 19.9693 8.27806 19.9774 8.42692 19.9774C12.9805 19.9774 17.5341 19.9713 22.0877 19.9774C22.8503 19.9774 23.2928 20.4811 23.3214 21.1643C23.3479 21.7883 22.7504 22.3307 22.104 22.3266C19.7527 22.3144 17.4015 22.3225 15.0503 22.3225L15.0483 22.3205Z"
            fill="#2560D7"/>
          <path
            d="M18.5132 11.6711C18.5132 10.5027 18.5132 9.33418 18.5132 8.1657C18.5132 7.43566 18.9598 6.94625 19.6429 6.95441C20.4097 6.96256 20.8562 7.44382 20.8603 8.1759C20.8705 10.5006 20.8542 12.8253 20.8685 15.1521C20.8726 15.7455 20.4341 16.2431 19.9182 16.4123C19.3003 16.6162 18.5071 15.9576 18.5152 15.3478C18.5295 14.1223 18.5193 12.8967 18.5193 11.6711H18.5152H18.5132Z"
            fill="#2560D7"/>
          <path
            d="M14.0435 12.989C14.0435 12.1977 14.0395 11.4065 14.0435 10.6153C14.0476 10.077 14.4453 9.60997 14.898 9.56715C15.8421 9.47946 16.1969 9.86692 16.3152 10.4257C16.356 10.6214 16.3805 10.8253 16.3825 11.0252C16.3886 12.3894 16.3927 13.7557 16.3825 15.1199C16.3784 15.6705 16.1684 16.0927 15.6321 16.3598C14.9979 16.6759 14.0537 16.1722 14.0476 15.3993C14.0435 14.5959 14.0476 13.7945 14.0476 12.991H14.0435V12.989Z"
            fill="#2560D7"/>
          <path
            d="M9.11217 13.5108C9.11217 12.8929 9.12848 12.273 9.10809 11.6551C9.07954 10.7619 9.97476 10.1705 10.8108 10.6008C11.2472 10.8251 11.4593 11.1922 11.4654 11.6632C11.4777 12.8093 11.4756 13.9533 11.4634 15.0993C11.4573 15.6846 11.2166 16.2454 10.4417 16.4309C9.83609 16.5757 9.13052 16.0292 9.13052 15.397C9.13052 14.769 9.13052 14.1388 9.13052 13.5108C9.1244 13.5108 9.11828 13.5108 9.11217 13.5108Z"
            fill="#2560D7"/>
        </svg>
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5">New Report</p>
        <p class="pt-2 text !text-[14px]">Please enter details to create new report.</p>
        <div class="pt-8 w-full flex items-center">
          <div class=" flex-1">

            <FloatingLabelInput id="report-name" class="w-full " customClass="h-[56px] bg-[#F4F6FA]" type="text" label="Report Name" placeholder="Enter name of report" v-model="getReportsAdd.name" :errorType="
                  validations.name ? 'danger' : ''">
              <template v-slot:error_message>
                <InputFieldMessage v-if="validations.name" :message="messages.name"></InputFieldMessage>
              </template>
            </FloatingLabelInput>

            <b-dropdown ref="select_campaign" right class="w-full !pt-3 dropdown-menu-right hide_dropdown_caret default_style_dropdown" :no-caret="true">
              <div :class="{'border !border-[#BE185D]': validations.campaigns}" class="w-full dropdown_header bg-[#F4F6FA] min-h-[3.5rem] px-3 py-2 rounded-[0.5rem] d-flex align-items-center" slot="button-content" data-cy="select-camp">
                <div>
                  <p :class="[getReportsAdd.campaigns.length ? '!text-[0.75rem]' : '!text-[0.875rem]']" class="text text-left">Select Campaigns</p>
                  <div :class="{'mt-[0.5rem]': getReportsAdd.campaigns.length}" class="flex flex-wrap gap-[0.5rem] w-full">
                    <div class="flex gap-x-[0.5rem] h-[1.813rem] items-center px-[0.5rem] gap-[0.25rem] border border-[#9BA5B7] rounded-2xl" v-for="item in getReportsAdd.campaigns">
                      <p class="text !text-[#3C4549] !leading-none">{{ getCampaignName(item) }}</p>
                      <svg @click.stop="removeCampaign(item)" xmlns="http://www.w3.org/2000/svg" class="w-[0.5rem] h-[0.5rem]" viewBox="0 0 8 8" fill="none">
                        <path d="M1.0001 1.3418L7.0001 7.3418M7 1.3418L1 7.3418" stroke="#3C4549" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <span class="arrow_icon flex items-center ml-auto">
                <InputFieldMessage v-if="validations.campaigns" message="Please select the campaigns"></InputFieldMessage>
                <i class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i>
              </span>
              </div>
              <ul class="clear inner w-full !px-0 !pb-2 !py-1">
                <template v-if="(tempCampaignList && tempCampaignList.length && tempCampaignList.length > 1) || search">
                  <div class="mx-[1.5rem] h-[2.375rem] mb-[1rem] mt-[0.8rem] border !border-[#757A8A] rounded-lg justify-between px-2 items-center flex">
                    <input placeholder="Search for campaign"
                           type="text"
                           class="w-full !h-[2.2rem] !border-none px-1"
                           v-model="search"
                           @input="searchFromCampaignList"
                           data-cy="search-camp"> <i
                    class="fal fa-search"></i>
                  </div>
                  <hr>
                </template>
                <template v-if="tempCampaignList && tempCampaignList.length">
                  <li class="cursor-pointer border-b group hover:rounded-tl-lg px-[1rem] py-[1rem] flex justify-between font-poppins items-center">
                    <Checkbox
                      id="select_all_campaings_from_list"
                      label="All Columns"
                      v-model="isSelectAllCampaign"
                      @click="$refs.select_campaign.hide(true)"
                      @change="selectAllCampaigns(isSelectAllCampaign)"
                      labelClass="font-semibold text-[#3C4549]"
                      customClass="w-full justify-between flex-row-reverse"
                    ></Checkbox>
                  </li>
                  <template v-for="(cta,index) in tempCampaignList">
                    <li class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex justify-between font-poppins items-center">
                      <Checkbox
                        :id="cta._id"
                        :label="limitTextLength(cta.name, 21)"
                        v-model="getReportsAdd.campaigns"
                        @click="$refs.select_campaign.hide(true)"
                        :inputValue="cta._id"
                        labelClass="text-[#3C4549]"
                        customClass="w-full justify-between flex-row-reverse"
                      ></Checkbox>
                    </li>
                  </template>

                </template>
              </ul>
            </b-dropdown>

            <b-dropdown ref="select_columns" right class="w-full !pt-[0.75rem] dropdown-menu-right hide_dropdown_caret default_style_dropdown" :no-caret="true">
              <div :class="{'border !border-[#BE185D]': validations.duration}" class="w-full dropdown_header bg-[#F4F6FA] min-h-[3.5rem] px-3 py-2 rounded-[0.5rem] d-flex align-items-center" slot="button-content" data-cy="select-camp">
                <div>
                  <p :class="[getReportsAdd.daily || getReportsAdd.weekly || getReportsAdd.monthly ? '!text-[0.75rem]' : '!text-[0.875rem]']" class="text text-left">How often would you like to receive the emails?</p>
                  <div :class="{'mt-[0.5rem]': getReportsAdd.daily || getReportsAdd.weekly || getReportsAdd.monthly}" class="flex flex-wrap gap-y-[0.5rem] gap-x-[0.5rem] w-full">

                    <div v-if="getReportsAdd.daily" class="flex gap-x-[0.5rem] h-[1.813rem] items-center px-[0.5rem] gap-[0.25rem] border border-[#9BA5B7] rounded-2xl">
                      <p class="text !text-[#3C4549] !leading-none">Daily</p>
                      <svg @click.stop="getReportsAdd.daily = !getReportsAdd.daily" xmlns="http://www.w3.org/2000/svg" class="w-[0.5rem] h-[0.5rem]" viewBox="0 0 8 8" fill="none">
                        <path d="M1.0001 1.3418L7.0001 7.3418M7 1.3418L1 7.3418" stroke="#3C4549" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div v-if="getReportsAdd.weekly" class="flex gap-x-[0.5rem] h-[1.813rem] items-center px-[0.5rem] gap-[0.25rem] border border-[#9BA5B7] rounded-2xl">
                      <p class="text !text-[#3C4549] !leading-none">Weekly</p>
                      <svg @click.stop="getReportsAdd.weekly = !getReportsAdd.weekly" xmlns="http://www.w3.org/2000/svg" class="w-[0.5rem] h-[0.5rem]" viewBox="0 0 8 8" fill="none">
                        <path d="M1.0001 1.3418L7.0001 7.3418M7 1.3418L1 7.3418" stroke="#3C4549" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div v-if="getReportsAdd.monthly" class="flex gap-x-[0.5rem] h-[1.813rem] items-center px-[0.5rem] gap-[0.25rem] border border-[#9BA5B7] rounded-2xl">
                      <p class="text !text-[#3C4549] !leading-none">Monthly</p>
                      <svg @click.stop="getReportsAdd.monthly = !getReportsAdd.monthly" xmlns="http://www.w3.org/2000/svg" class="w-[0.5rem] h-[0.5rem]" viewBox="0 0 8 8" fill="none">
                        <path d="M1.0001 1.3418L7.0001 7.3418M7 1.3418L1 7.3418" stroke="#3C4549" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <span class="arrow_icon flex items-center ml-auto">
                <InputFieldMessage v-if="validations.duration" message="Please select the duration"></InputFieldMessage>
                <i class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i>
              </span>
              </div>
              <ul class="clear inner w-full !px-0 !pb-2 !py-1">
                <li class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex justify-between font-poppins items-center">
                  <Checkbox
                    id="daily"
                    label="Daily"
                    v-model="getReportsAdd.daily"
                    @click="$refs.select_columns.hide(true)"
                    labelClass="text-[#3C4549]"
                    customClass="w-full justify-between flex-row-reverse"
                  ></Checkbox>
                </li>
                <li class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex justify-between font-poppins items-center">
                  <Checkbox
                    id="weekly"
                    label="Weekly"
                    v-model="getReportsAdd.weekly"
                    @click="$refs.select_columns.hide(true)"
                    labelClass="text-[#3C4549]"
                    customClass="w-full justify-between flex-row-reverse"
                  ></Checkbox>
                </li>
                <li class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex justify-between font-poppins items-center">
                  <Checkbox
                    id="monthly"
                    label="Monthly 1st of each month"
                    v-model="getReportsAdd.monthly"
                    @click="$refs.select_columns.hide(true)"
                    labelClass="text-[#3C4549]"
                    customClass="w-full justify-between flex-row-reverse"
                  ></Checkbox>
                </li>
              </ul>
            </b-dropdown>

            <div class="tag_input !pt-3">
              <div class="tag_input_inner">
                <FloatingLabelInput
                  id="emails"
                  v-model="emailField"
                  type="text"
                  label="Where should we send an email to?"
                  @keyup.enter="addEmail"
                  customClass="h-[3.5rem] bg-[#F4F6FA]"
                  :errorType="validations.emails ? 'danger' : ''"
                >
                  <template v-slot:error_message>
                    <InputFieldMessage v-if="validations.emails" :message="messages.emails"></InputFieldMessage>
                    <InputFieldMessage v-else-if="getReportsAdd.emailNotAllowed" message="Emails more than 25 is not allowed"></InputFieldMessage>
                  </template>
                </FloatingLabelInput>
              </div>
              <div class="tag-item-list mt-[0.5rem] mb-2" v-if="getReportsAdd.emails.length > 0">
                <ul>
                  <li class="!bg-[#F4F6FA] !mr-[0.4rem] !shadow-none" v-for="(item, index) in getReportsAdd.emails">
                    <span class="text">{{ item.name }}</span>
                    <span @click.prevent="getReportsAdd.emails.splice(index, 1)" class="cross_icon"></span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="flex justify-center items-center">
              <div class="pt-10 pb-11 flex gap-x-3 justify-between items-center">
              <Button
                id="login-button"
                type="button"
                class="border border-gray-500 hover:bg-[#F2F3F8]"
                buttonClass="btn-lg"
                @click="$bvModal.hide('addReportModalV2')"
              >
                <template v-slot:label>Close</template>
              </Button>
              <Button
                id="login-button"
                type="button"
                class="text-white bg-[#2560D7] hover:bg-blue-700"
                buttonClass="btn-lg"
                :disabled="getReportsLoaders.store"
                @click="validateAndStoreReport()"
              >
                <template v-if="!getReportsLoaders.store" v-slot:label>Add</template>
                <template v-if="getReportsLoaders.store" v-slot:loader>
                  <Loader></Loader>
                </template>
              </Button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>


<script>
import {mapActions, mapGetters} from 'vuex'
import Multiselect from 'vue-multiselect'
import {reportTypes} from '@/state/modules/mutation-types'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'

export default ({
  components: {
    Multiselect,
    FloatingLabelInput: () => import('@/ui/ui-kit/v2/FloatingLabelInput.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue'),
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
    Checkbox: () => import('@/ui/ui-kit/v2/Checkbox.vue')
  },
  data() {
    return {
      size: '14px',
      color: DARK_PURPLE_LOADER_COLOR,
      colorWhite: '#ffffff',
      messages: {
        name: 'Name cannot be empty',
        brands: 'Brands cannot be empty',
        campaigns: 'Campaigns cannot be empty',
        duration: 'Please select how often would you like to receive the emails',
        emails: 'Please add email address on which you would like to receive the reports.'
      },
      campaign: {},
      validations: {
        name: false,
        brands: false,
        campaigns: false,
        duration: false,
        emails: false
      },

      emailField: '',

      allCampaign: [],

      search: '',
      isSelectAllCampaign: true,
      tempCampaignList: []
    }
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'getReportsAdd',
      'getReportsLoaders',
      'getCampaigns',
      'getCampaignsList',
      'getWorkspace'
    ]),
    getEmailOptions() {
      if (!this.getReportsAdd.emailsOption.find(item => item.name === this.getProfile.email)) {
        if (this.getProfile.email) {
          this.getReportsAdd.emailsOption.push({
            'name': this.getProfile.email
          })
        }
      }
      return this.getReportsAdd.emailsOption
    },
    computeButtonTitle() {
      return this.getReportsAdd._id ? 'Update' : 'Add'
    },
  },
  methods: {
    ...mapActions(['fetchBrandList', 'fetchCampaignsList']),
    getCampaignName(id) {
      const cta = this.allCampaign.find(item => item._id === id)
      return cta ? cta.name : ''
    },
    removeCampaign(index) {
      this.getReportsAdd.campaigns.splice(index, 1)
    },

    /**
     * Fetch all the required data for the model before model is opened
     * @description Fetch all the required data for the model
     * @returns {Promise<void>}
     */
    async preRequiredModelData() {
      this.allCampaign = await this.fetchCampaignsList({ page: 1, isFetchAll: true })
      this.selectAllCampaigns()
    },
    searchFromCampaignList() {
      if(this.search && this.search.length) {
        this.tempCampaignList = JSON.parse(JSON.stringify(this.allCampaign.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()))))
        return
      }
      this.tempCampaignList = this.getFilteredCampaigns()
    },
    selectAllCampaigns() {
      if(this.isSelectAllCampaign) {
        this.tempCampaignList = this.getFilteredCampaigns()
        this.getReportsAdd.campaigns = this.getFilteredCampaigns().map(item => item._id)
        return
      }
      this.getReportsAdd.campaigns = []
    },
    getFilteredCampaigns () {
      return JSON.parse(JSON.stringify(this.allCampaign.filter(item => item.brand_id === this.getWorkspace._id)))
    },

    async validateAndStoreReport() {
      this.validations.name = this.requiredCheck(this.getReportsAdd.name)
      this.validations.brands = !this.getWorkspace._id
      this.validations.campaigns = (this.getReportsAdd.campaigns.length === 0)
      this.validations.emails = (this.getReportsAdd.emails.length === 0)
      this.validations.duration = (!this.getReportsAdd.weekly && !this.getReportsAdd.daily && !this.getReportsAdd.monthly)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        this.getReportsAdd.campaigns = this.allCampaign.map(item => this.getReportsAdd.campaigns.includes(item._id) ? item : null).filter(item => item !== null)
        await this.$bvModal.hide('addReportModalV2')
        await this.$store.dispatch('storeReport')
      }
    },
    addEmail () {
      if (this.getReportsAdd.emails.length < 25) {
        if (this.validateEmail(this.emailField)) {
          const email = {
            name: this.emailField
          }
          this.$store.commit(reportTypes.SET_REPORTS_ADD_EMAIL_SELECTION, [...new Set(this.getReportsAdd.emails.concat(email))])
          this.$store.commit(reportTypes.SET_REPORTS_ADD_EMAIL_OPTIONS, [...new Set(this.getReportsAdd.emailsOption.concat(email))])
          this.emailField = ''
        } else {
          this.alertMessage('Please add valid email.', 'error')
        }
      } else {
        this.getReportsAdd.emailNotAllowed = true
      }
    },
  },
  watch: {
    'getReportsAdd.name'(value) {
      if (value && value.trim() && value.length > 0) this.validations.name = false
    },
    'getReportsAdd.campaigns'(value) {
      if (value && value.length > 0) this.validations.campaigns = false
    },
    'getReportsAdd.emails'(value) {
      if (value && value.length > 0) this.validations.emails = false
    }
  }
})
</script>
<style lang="less">
.add-report {
  .modal-dialog {
    max-width: 41.7rem !important;
  }

  .modal-content {
    border-radius: 16px !important;
    border-color: #F2F3F8 !important;
  }

  .modal-body {
    padding: 40px 40px 56px 40px !important;
  }
}
</style>

